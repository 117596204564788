/* You can add global styles to this file, and also import other style files */
@import '~@ng-select/ng-select/themes/default.theme.css';

.overlay {
  visibility: hidden;
  opacity: 0;
}

/*switch inicio*/
.switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 19px;
  vertical-align: middle;
  margin: 0px 10px;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #777777;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 13px;
  width: 13px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.switch input {
  display: none;
}

input:checked+.slider {
  background-color: #006699;
}

input:focus+.slider {
  box-shadow: 0 0 1px #006699;
}

input:checked+.slider:before {
  -webkit-transform: translateX(15px);
  -ms-transform: translateX(15px);
  transform: translateX(15px);
}

.slider.round {
  border-radius: 19px;
}

.round {
  line-height: 45px !important;
  width: 35px !important;
  height: 19px !important;
}

.slider.round:before {
  border-radius: 50%;
}

.disabled .slider {
  cursor: default;
  background-color: #ccc !important;
}

.form-check-inline label {
  font-size: 12px;
  text-transform: capitalize;
  font-weight: 400;
  vertical-align: middle;
}

.holder_switch {
  display: inline-block;
}

.holder_switch p {
  color: #777777;
  font-family: 'Open Sans';
  font-size: 13px;
  font-weight: 700;
  display: inline-block;
  margin-bottom: 0px;
  vertical-align: middle;
  line-height: 25px;
}

/*switch fim*/

.page-link {
  color: #7460ee !important;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff !important;
  background-color: #7460ee !important;
  border-color: #7460ee !important;
}

.overlay.carregando {
  background-color: rgba(245, 245, 245, 0.7);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  visibility: visible;
  opacity: 1;
  z-index: 9999;
}

.overlay.carregando:after {
  content: '';
  background: url('assets/images/loading.svg');
  width: 80px;
  height: 80px;
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  display: block;
  z-index: 1;
}

.loadinghalf {
  background-color: rgba(245, 245, 245, 0.7);
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  opacity: 1;
  z-index: 9;
}

.safe-arealoader .loadinghalf {
  display: block;
}

.safe-arealoader {
  position: relative;
}

.loadinghalf .loadspinner {
  background-image: url('assets/images/loading.gif');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 40px 40px;
  width: 40px;
  height: 40px;
  margin-left: -20px;
  margin-top: -20px;
  position: absolute;
  top: 50%;
  left: 50%;
}

.accordion .card {
  border: none !important;
  border-bottom: 1px dashed #cfcdcb !important;
  box-shadow: none !important;
}

.accordion .card .btn-link {
  font-size: 16px;
}

.accordion .card .btn-link:hover {
  text-decoration: none;
}

.accordion .card .btn-link.focus {
  text-decoration: none;
}

.accordion .card .btn-link:focus {
  text-decoration: none;
}

.accordion .card:first-child {
  border-top: 1px dashed #cfcdcb !important;
}

.accordion .card-header {
  border: none !important;
  background-color: white !important;
}

table tr th {
  color: #525b60;
}

app-select.ng-invalid.ng-touched>div>.ng-select>.ng-select-container {
  border: solid 1px #fc4b6c;
}

.ng-select.invalid>.ng-select-container {
  border: solid 1px #fc4b6c;
}

.scroll-to-top {
  position: fixed;
  bottom: 15px;
  right: 15px;
  opacity: 0;
  transition: all 0.2s ease-in-out;
  color: white;
}

.show-scrollTop {
  opacity: 1;
  transition: all 0.2s ease-in-out;
}

.nav-tabs .nav-item .nav-link {
  color: #00826a;
}

.nav-tabs .nav-item .nav-link.active {
  color: white;
  background-color: #00826a;
}

.btn-usm {
  padding: 0.10rem 0.20rem 0.10rem 0.20rem !important;
}

td.hidden-performace {
  filter: blur(5px) !important;
  color: lightslategray !important;
}

.custom-outline-btn {
  color: #00826a !important;
  background-color: transparent !important;
  border-color: #00826a !important;
}

.custom-outline-btn:hover {
  color: #fff !important;
  background-color: #00826a !important;
  border-color: #00826a !important;
}

.table-top-sticky {
  position: sticky !important;
  top: 0 !important;
  background-color: #fff !important;
}

.custom-btn-pesquisa-not {
  color: #00826a !important;
  background-color: transparent !important;
  border-color: #00826a !important;
}

.custom-btn-pesquisa {
  color: #fff !important;
  background-color: #00826a !important;
  border-color: #00826a !important;
}

.custom-btn-pesquisa:hover {
  color: #fff !important;
  background-color: #00826a !important;
  border-color: #00826a !important;
}

.nowrap {
  white-space: nowrap;
}

@media (min-width: 768px) and (max-width: 1200px) {
  h5.font-size-control-acordos {
    font-size: 14px !important;
  }

  h6.font-size-control-acordos {
    font-size: 12px !important;
  }
}

@media (max-width: 767px) {
  h5.font-size-control-acordos {
    font-size: 16px !important;
  }

  h6.font-size-control-acordos {
    font-size: 14px !important;
  }
}

/* ============ NGX SLIDER CUSTOM STYLE ============ */

.custom-slider .ngx-slider .ngx-slider-bar {
  height: 20px !important;
  background-color: #d8e0f3 !important;
}

.custom-slider .ngx-slider .ngx-slider-pointer {
  width: 15px;
  height: 20px;
  top: auto;
  bottom: 0;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.custom-slider .ngx-slider .ngx-slider-pointer:after {
  display: none;
}

.custom-slider .ngx-slider .ngx-slider-bubble {
  bottom: 20px;
}

.custom-slider .ngx-slider .ngx-slider-tick {
  width: 5px;
  height: 24px;
  margin-left: 4px;
  border-radius: 4px;
  top: -1px;
}

.custom-slider .ngx-slider .ngx-slider-selection {
  background-image: url(/assets/images/background/barra-degrade-desconto.png);
  background-size: cover;
}

.custom-slider .ngx-slider[disabled] .ngx-slider-tick {
  background-color: #8b91a2;
}

.custom-slider .ngx-slider[disabled] .ngx-slider-bar {
  background-color: #d8e0f3;
}

.custom-slider .ngx-slider .ngx-slider-tick.ngx-slider-selected {
  background: #00826a;
}

/* ============ NGX SLIDER CUSTOM STYLE (end) ============ */

.background-secondary {
  background-color: var(--primary);
}

.btn-close {
  color: 0.75rem;
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$btn-close-color}'><path d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/></svg>");
  background-position: center;
  box-shadow: none;
}

.btn-close:hover {
  color: #000000;
}

.btn-close .white {
  color: white
}

.btn-close:focus {
  box-shadow: none !important;
}
