@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100;0,9..40,200;0,9..40,300;0,9..40,400;0,9..40,500;0,9..40,600;0,9..40,700;0,9..40,800;0,9..40,900;0,9..40,1000;1,9..40,100;1,9..40,200;1,9..40,300;1,9..40,400;1,9..40,500;1,9..40,600;1,9..40,700;1,9..40,800;1,9..40,900;1,9..40,1000&display=swap');
@import url(icons/font-awesome/css/fontawesome-all.css);
@import url(icons/simple-line-icons/css/simple-line-icons.css);
@import url(icons/weather-icons/css/weather-icons.min.css);
@import url(icons/themify-icons/themify-icons.css);
@import url(icons/flag-icon-css/flag-icon.min.css);
@import url(icons/material-design-iconic-font/css/materialdesignicons.min.css);
@import url(icons/crypto-icons/cryptocoins.css);

/* Mandatory */
/* @import '~ng-wizard/themes/ng_wizard.min.css'; */

/* Optional */
/* If a theme other than default is used, the css file for that theme is required. */
/* @import '~ng-wizard/themes/ng_wizard_theme_arrows.min.css';
@import '~ng-wizard/themes/ng_wizard_theme_circles.min.css';
@import '~ng-wizard/themes/ng_wizard_theme_dots.min.css'; */

:root {
  --primary: #00826a;
  --secondary: #1f2a36;
  --headerBackground: #fff;
  --footerBackground: #1f2a36;
  --footerText: #fff;
}

html,
body {
  font-family: 'DM Sans', sans-serif;
  font-size: 14px;
  height: 100%;
}

#main {
  min-height: calc(100vh - 182px);
  margin-top: 78px;
}

#nav-menu {
  background-color: #fff;
}

.site-footer {
  margin-top: 20px !important;
  padding: 20px 0px 20px 0px;
  background: var(--footerBackground);
  color: var(--footerText);
}

.site-footer ul {
  height: 100%;
  padding: 0 0 50px 26px;
  margin: 0 0 0 26px;
  border-left: 1px solid #fff;
  list-style: none;
}

.site-footer ul a {
  display: block;
  font-size: 16px;
  margin: 0 0 18px;
  transition: all 0.36s;
}

.site-footer ul a:hover {
  color: var(--primary);
  transition: all 0.26s;
}

.site-footer span {
  font-size: 24px;
  margin: 0 4px;
  color: var(--primary);
}

.site-footer small {
  display: block;
  margin-top: 42px;
  font-size: 14px;
  font-weight: 300;
}

.clearfix::after {
  content: '';
  clear: both;
  display: table;
}

a,
a:visited,
a:hover {
  color: var(--secondary);
  text-decoration: none;
}

img {
  height: auto;
  max-width: 100%;
}

.align-between {
  align-content: space-between;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

header .navbar-collapse {
  justify-content: center;
}

header .white-label {
  justify-content: end;
}

header .navbar {
  background-color: var(--headerBackground);
}

header.interno .navbar-collapse {
  justify-content: flex-end;
}

header.interno a.active {
  border-bottom: 1px solid var(--primary);
}

header {
  /*padding: 16px 0 8px;*/
  /* background: var(--headerBackground); */
}

header .nav-link {
  font-size: 16px;
  padding-right: 10px !important;
  padding-left: 10px !important;
  display: block;
  transition: all 0.26s;
}

header .nav-link:hover {
  color: var(--primary) !important;
  transition: all 0.26s;
}

header span {
  font-size: 24px;
  display: block;
  padding: 4px;
  color: var(--primary);
}

.btn-primary {
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;
}

.btn-primary:hover,
.btn-primary:active,
.btn-primary:focus {
  color: #fff;
  background-color: var(--primary);
  border-color: var(--primary);
}

.input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n + 3),
.input-group:not(.has-validation)> :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
  border-radius: 0.35rem;
}

.input-group:not(.has-validation)>.custom-file:not(:last-child) .custom-file-label::after,
.input-group:not(.has-validation)>.custom-select:not(:last-child),
.input-group:not(.has-validation)>.form-control:not(:last-child) {
  border-radius: 0.35rem;
}

#page-login {
  background: var(--gradient-escuro, linear-gradient(68deg, var(--login-gradient-1) 9.18%, var(--login-gradient-2) 134.67%));
  background-size: 90% 100% !important;
  background-position: left center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  height: 100vh;
  position: relative;
  z-index: 1;
}

#card-login {
  height: 100vh;
  background-color: #fff;
  width: 100% !important;
}

.dropdown-menu-profile {
  width: 300px;
}

.dropdown-menu-profile-content {
  margin-left: 10px;
}

.text-primary {
  color: var(--primary) !important;
}

a.text-primary:hover,
a.text-primary:focus {
  color: var(--primary) !important;
}

.text-secondary {
  color: var(--secondary) !important;
}

a.text-secondary:hover,
a.text-secondary:focus {
  color: var(--secondary) !important;
}

.bg-primary {
  background-color: var(--primary) !important;
}

a.bg-primary:hover,
a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: var(--primary) !important;
}

.bg-secondary {
  background-color: var(--secondary) !important;
}

a.bg-secondary:hover,
a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: var(--secondary) !important;
}

.page-link {
  color: var(--primary) !important;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff !important;
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;
}

.dropdown-item {
  color: var(--secondary) !important;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: var(--primary);
}

.nav-link:focus,
.nav-link:hover {
  color: var(--primary);
}

.swal2-styled.swal2-confirm {
  border: 0;
  border-radius: 0.25em;
  background: initial;
  background-color: var(--primary) !important;
  color: #fff;
  font-size: 1em;
}

.accordion-dashed {
  border: none;
  border-top: 1px dashed #cfcdcb;
  border-bottom: 1px dashed #cfcdcb;
}

.accordion-button:focus {
  z-index: 3;
  border-color: unset;
  outline: 0;
  box-shadow: unset;
}

.accordion-button:not(.collapsed) {
  color: #000;
  background-color: #fff;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}

.accordion-button.collapsed::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23d91b5c'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23d91b5c'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  transform: rotate(-180deg);
}

.form-group {
  margin-bottom: 10px;
}

.form-control-label {
  margin-bottom: 5px;
}

.input-group>.form-input-calendar {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.input-group>.fa-calendar {
  border-color: #ced4da;
}

input:checked+.slider {
  background-color: var(--primary);
}

.toast-success {
  background-color: var(--primary) !important;
}

.ngb-dp-body {
  z-index: 1055 !important;
}

.text-warning-soft {
  color: #fff0d5;
}

.text-success-soft {
  color: #c3e6cb;
}

.bg-warning-soft {
  background-color: #fff0d5 !important;
}

.bg-success-soft {
  background-color: #c3e6cb !important;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1.5rem;
  list-style: none;
  background-color: #eef5f9;
  border-radius: 4px;
}

.breadcrumb-item+.breadcrumb-item {
  padding-left: 0.5rem;
}

.breadcrumb-item+.breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: '/';
}

.breadcrumb-item+.breadcrumb-item:hover::before {
  text-decoration: underline;
}

.breadcrumb-item+.breadcrumb-item:hover::before {
  text-decoration: none;
}

.breadcrumb-item.active {
  color: #6c757d;
}

/*******************
 Breadcrumb and page title
*******************/
.page-titles {
  /* padding: 15px 30px; */
  /* background: #fff; */
  /* box-shadow: 1px 0 5px rgba(0, 0, 0, 0.1); */
  margin-bottom: 15px;
}

.page-titles .text-themecolor {
  color: #1e88e5;
}

.page-titles .breadcrumb {
  padding: 0;
  margin: 0;
  background: transparent;
  font-size: 14px;
}

.page-titles .breadcrumb .breadcrumb-item+.breadcrumb-item::before {
  content: '\e649';
  font-family: themify;
  color: #ced4da;
  font-size: 11px;
}

.btn-check:focus+.btn-secondary,
.btn-secondary:focus {
  box-shadow: none;
}

.btn-check:focus+.btn,
.btn:focus {
  box-shadow: none;
}

/* .ng-wizard-theme-arrows > ul.step-anchor > li.active > a {
  border-color: var(--primary) !important;
  color: #fff !important;
  background: var(--primary) !important;
}

.ng-wizard-theme-arrows > ul.step-anchor > li.active > a:after {
  border-left: 30px solid var(--primary) !important;
}

.ng-wizard-theme-arrows > ul.step-anchor > li.done > a {
  border-color: #fbe3eb !important;
  color: #000 !important;
  background: #fbe3eb !important;
  z-index: 1;
}

.ng-wizard-theme-arrows > ul.step-anchor > li.done > a:after {
  border-left: 30px solid #fbe3eb;
}

.ng-wizard-theme-arrows > ul.step-anchor > li.active > a:hover,
.ng-wizard-theme-arrows > ul.step-anchor > li.active > a:focus {
  z-index: 5;
}

.ng-wizard-theme-arrows > ul.step-anchor > li.disabled > a,
.ng-wizard-theme-arrows > ul.step-anchor > li.disabled > a:hover {
  color: #000 !important;
} */

.wizard li {
  background-color: #e4e4e4;
  border-radius: 5px;
  display: inline;
  padding: 10px 30px 10px 40px;
  margin-right: -7px;
  width: auto;
}

.wizard li::before,
.wizard li::after {
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  border-color: transparent;
  border-left-color: #fff;
  border-radius: 10px;
}

.wizard li::before {
  border-width: 25px;
  margin-top: -16px;
  margin-left: 84px;
}

.wizard li::after {
  border-left-color: #e4e4e4;
  border-width: 21px;
  margin-top: -12px;
  margin-left: 24px;
}

.wizard li.selected {
  background-color: var(--primary);
  color: #fff;
}

.wizard li.selected::after {
  border-left-color: var(--primary);
}

.wizard li:last-child::after {
  border-left-color: transparent;
}

.sw-theme-arrows>.nav .nav-link.active {
  color: #ffffff;
  border-color: var(--primary) !important;
  background: var(--primary) !important;
  cursor: pointer;
}

.sw-theme-arrows>.nav .nav-link.active::after {
  border-left-color: var(--primary) !important;
}

.sw-theme-arrows>.nav .nav-link.done {
  color: var(--primary) !important;
  border-color: #fbe3eb !important;
  background: #fbe3eb !important;
  cursor: pointer;
}

.sw-theme-arrows>.nav .nav-link.done::after {
  border-left-color: #fbe3eb !important;
}

.sw-theme-arrows,
.sw-theme-arrows .tab-content {
  min-height: 300px;
}

.font-10 {
  font-size: 10px;
}

.font-12 {
  font-size: 12px;
}

.font-15 {
  font-size: 15px;
}

.font-20 {
  font-size: 20px;
}

.font-weight-bold {
  font-weight: 500 !important;
}

.mr-2 {
  margin-right: 0.5rem !important;
}

.holder_switch p {
  font-family: 'DM Sans', sans-serif;
}

.border-primary {
  border-color: var(--primary) !important;
}

.alert-dark-primary {
  color: #000;
  background-color: #fbe3eb;
  border-color: #fbe3eb;
}

.btn-dark-success {
  color: #fff;
  background-color: #25d366;
  border-color: #22c35d;
}

.btn-info {
  color: #fff;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}

.btn-dark-success:hover,
.btn-info:hover {
  color: #fff;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card {
  border: none;
}

.font-weight-normal {
  font-weight: normal;
}

th {
  font-weight: 600 !important;
  font-size: 14px;
}

td {
  font-weight: normal !important;
  font-size: 13px;
}

/* ============ desktop view ============ */
@media all and (min-width: 992px) {
  .dropdown-menu li {
    position: relative;
  }

  .nav-item .submenu {
    display: none;
    position: absolute;
    left: 100%;
    top: -7px;
  }

  .nav-item .submenu-left {
    right: 100%;
    left: auto;
  }

  .dropdown-menu>li:hover {
    background-color: #f1f1f1;
  }

  .dropdown-menu>li:hover>.submenu {
    display: block;
  }
}

/* ============ desktop view .end// ============ */

/* ============ small devices ============ */
@media (max-width: 991px) {
  .dropdown-menu .dropdown-menu {
    margin-left: 0.7rem;
    margin-right: 0.7rem;
    margin-bottom: 0.5rem;
  }
}

/* ============ small devices .end// ============ */
